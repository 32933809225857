/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

// Images
import phone from 'img/phone.svg'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import GravityForm from 'components/GravityForm'
import HeroDefault from 'components/elements/HeroDefault'
import TitleDefault from 'components/elements/TitleDefault'

// Third Party
import styled from 'styled-components'
import ButtonDefault from '../components/elements/ButtonDefault'

import Testimonials from '../components/Testimonials'

const Container = styled.div`
  position: relative;  
`

const Contact = styled.div``

const FormContainer = styled.div`
  box-shadow: 0px 0px 4px #00000080;
  max-width: 600px;
  border-bottom-left-radius: 20px;
`

const FormTitle = styled.div`
  height: 50px;
  max-width: 600px;
  padding-left: 100px;
  padding-right: 100px;

  h2 {
    font-size: ${props => props.theme.font.size.ml};

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size.xm};
    }
  }

  @media screen and (max-width: 991px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const FormWrapper = styled.div`
  padding-left: 100px;
  padding-right: 100px;

  @media screen and (max-width: 991px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const Info = styled.div`
  width: 320px;

  @media (max-width: 991px){
    width: 560px;
  }

  a {
    color: ${props => props.theme.color.text.light};
    &:hover {
      text-decoration: underline;
    }
  }
`

const Icon = styled.img`
  width: 23px;
  height: 23px;
`

const StyledButtonDefault = styled(ButtonDefault)`
  transition: transform 0.25s;

  &:hover {
    transform: scale(1.05);
  }
`

const PageTemplate = ({
  data: {
    page: { 
      title,
      path, 
      acf: {
        banner_image: bannerImage,
        content_group: contentGroup,
        banner_title: bannerTitle,
        phonenumber,
        streetname,
        housenumber,
        zipcode,
        city,
        email_address: email,
        zeewolde,
        testimonial
      },
      yoastMeta
    },
  },
}) => {

  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      <HeroDefault 
        image={bannerImage} 
      />
      <section>
        <Container className="container py-3 py-lg-5">
          <div className="row">
            <div className="col">
              <TitleDefault h1 className="ml-lg-5 pl-lg-5">{parse(bannerTitle)}</TitleDefault>
            </div>
          </div>
        </Container>
      </section>
      <Contact className="color-background-soft py-5">
        <div className="container pb-lg-3">
          <div className="row">
            <div className="col-lg-8 d-lg-block d-flex justify-content-center py-lg-0 py-4e">
              <FormContainer className="d-flex justify-content-center flex-wrap color-background-light">
                <FormTitle className="w-100 d-flex align-items-center color-background-contrast">
                  <h2 className="mb-0 font-family-secondary-alt color-text-light">{contentGroup.form_title}</h2>
                </FormTitle>
                <FormWrapper className="w-100 pb-5 pt-4">
                  <GravityForm id={1} />
                </FormWrapper>
              </FormContainer>
            </div>
            <div className="col-lg-4 d-lg-block d-flex flex-wrap justify-content-center py-lg-0 py-4">
              <Info className="color-background-dark px-5 pt-4 pb-4 mb-4">
                <h2 className="mb-0 mt-2 font-size-xm font-family-secondary-alt color-text-light">{contentGroup.cta_title}</h2>

                <div className="d-flex justify-content-center py-4">
                  <StyledButtonDefault>
                    <Icon className="mr-2" src={phone} alt="" />
                    <p className="font-size-m font-family-secondary-alt mb-0"><a href={`tel:${phonenumber}`}>{phonenumber}</a></p>
                  </StyledButtonDefault>
                </div>

                <h2 className="font-size-xsm color-text-light font-family-secondary-alt">WelBijWim Bunschoten</h2>
                <p className="font-size-sm color-text-light mb-0">{`${streetname} ${housenumber}`}</p>
                <p className="font-size-sm color-text-light mb-0">{zipcode}</p>
                <p className="font-size-sm color-text-light mb-3">{city}</p>
                <a href={`mailto:${email}`} className="font-size-sm color-text-light">{email}</a>
              </Info>

              {/* <Info className="color-background-dark px-5 pt-4 pb-4">
                <h2 className="mb-0 mt-2 font-size-xm font-family-secondary-alt color-text-light">Direct contact met Rik?</h2>

                <div className="d-flex justify-content-center py-4">
                  <StyledButtonDefault>
                    <Icon className="mr-2" src={phone} alt="" />
                    <p className="font-size-m font-family-secondary-alt mb-0"><a href={`tel:${zeewolde.phone}`}>{zeewolde.phone}</a></p>
                  </StyledButtonDefault>
                </div>

                <h2 className="font-size-xsm color-text-light font-family-secondary-alt">WelBijWim Zeewolde</h2>
                <p className="font-size-sm color-text-light mb-0">{`${zeewolde.street} ${zeewolde.housenumber}`}</p>
                <p className="font-size-sm color-text-light mb-0">{zeewolde.zip}</p>
                <p className="font-size-sm color-text-light mb-3">{zeewolde.city}</p>
                <a href={`mailto:${email}`} className="font-size-sm color-text-light">{zeewolde.email}</a>
              </Info> */}
            </div>
          </div>
        </div>
      </Contact>

      <Testimonials image={testimonial.image.localFile.childImageSharp.fluid} id={[testimonial.quote]} />
      
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        content_group { 
          title
          form_title
          cta_title
        }
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        zeewolde {
          phone
          email
          street
          housenumber
          zip
          city
        }
        banner_title
        phonenumber
        email_address
        streetname
        housenumber
        zipcode
        city
        testimonial {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          quote
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`