/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

// Elements
import TitleDefault from 'components/elements/TitleDefault'
import ContentDefault from 'components/elements/ContentDefault'

const Testimonials = ({ className, id, image }) => {
  const { testimonial } = useStaticQuery(graphql`
    {
      testimonial: allWordpressWpTestimonials {
        edges {
          node {
            wpId: wordpress_id
            acf {
              testimonial {
                name
                quote
              }
            }
          }
        }
      }
    }
  `)

  let testimonalPost = testimonial.edges

  if(id && id.length > 0){
    testimonalPost = testimonial.edges.filter(({ node: { wpId } }) => id.indexOf(wpId) !== -1)
  }

  return (
    <StyledTestimonial>
      <Image className="col-md-12 p-0">
        <BgImage fluid={image} />
      </Image>
      <Container className="container h-100 d-flex flex-column justify-content-center">
        <div className="row mr-0 mr-lg-5 justify-content-end">
          {testimonalPost.map(({ node: { wpId, acf } }) => (
            <Testimonial className="col-lg-4 p-0 mr-lg-5 ml-3 ml-lg-0" key={wpId}>
              <TestimonialHeader className="p-3">
                <Title>Wat anderen zeggen</Title>
              </TestimonialHeader>
              <TestimonialContent className="pb-0 px-4 pt-4">
                <Content content={acf.testimonial.quote} />
              </TestimonialContent>
              <TestimonialBottom className="pr-4 pb-4">
                <Name className="text-right">{`- ${acf.testimonial.name}`}</Name>
              </TestimonialBottom>
            </Testimonial>
          ))}
        </div>
      </Container>
    </StyledTestimonial>
  )
}

const StyledTestimonial = styled.div`
  position: relative;
  
  @media screen and (min-width: 992px) {
    height: 70vh;
    min-height: 450px;
    max-height: 488px;
  }

  @media screen and (max-width: 991px) {
    height: 488px;
  }
`

const Container = styled.div`
  position: relative;
  z-index: 1;
`

const Image = styled.div`
  position: absolute;
  left: auto;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
`

const BgImage = styled(BackgroundImage)`
  height: 488px;
  width: 100%;
  z-index: 1;

  &::before {
    border-bottom-left-radius: 25px;
  }
`

const Testimonial = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.light};
  border-bottom-left-radius: 20px;
  box-shadow: 0px 0px 4px #00000080;
`
const TestimonialHeader = styled.div`
  background-color: ${props => props.theme.color.contrast};
`

const Title = styled.h4`
  font-family: ${props => props.theme.font.family.secondaryAlt};
  font-size: ${props => props.theme.font.size.l};
  color: rgba(255,255,255,.25);
  margin-bottom: 0;
`

const TestimonialContent = styled.div`
  position: relative;
`

const Content = styled(ContentDefault)`
  position: relative;
  font-size: ${props => props.theme.font.size.sm};
  line-height: ${props => props.theme.font.size.l};
  font-style: italic;
`

const TestimonialBottom = styled.div`
  position: relative;
`

const Name= styled.p`

`

export default Testimonials